<template>
   <div class="row" v-if="settings.idPermission == null">
      <div class="col-12">
         <div class="container">
            <div class="card">
               <div class="bg-banner">
                  <h1>Whitelist <span class="mx-4 d-none d-sm-inline">|</span> {{ $t("navBar.whitelist.settings") }}</h1>
               </div>
               <div class="card-body">
                  <div class="row m-0">
                     <div class="col-xl-6 px-1">
                        <div class="card mb-2">
                           <div class="card-body">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-1"><i class="far fa-exclamation-triangle color-theme font-13 mr-1"></i>{{ $t("whitelistSettings.correct.title") }} </h6>
                                    <p class="text-center mb-0">{{ $t("whitelistSettings.correct.desc") }} </p>
                                    <hr>
                                 </div>
                                 <div class="col-12 px-1">
                                    <input type="text" class="form-control" v-mask="'#####'" v-model="settings.minimumCorrect" />
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="card mb-2">
                           <div class="card-body">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-1"><i class="far fa-lock color-theme font-13 mr-1"></i> {{ $t("whitelistSettings.permission.title") }}</h6>
                                    <p class="text-center mb-0">{{ $t("whitelistSettings.permission.desc") }}</p>
                                    <hr>
                                 </div>
                                 <div class="col-12 px-1">
                                    <v-select title="Desktop" :options="permissionsList" v-model="settings.permission" label="name" />
                                    <select title="Mobile" class="form-control" v-model="settings.permission">
                                       <option v-for="(option, index) in permissionsList" :key="index" :value="option">{{ option.name }}</option>
                                    </select>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="card mb-2">
                           <div class="card-body">
                              <div class="row m-0 justify-content-center">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-1"><i class="far fa-lock color-theme font-13 mr-1"></i> {{ $t("whitelistSettings.automatic.title") }}</h6>
                                    <p class="text-center mb-0">{{ $t("whitelistSettings.automatic.desc") }}</p>
                                    <hr>                              
                                 </div>
                                 <div  class="d-flex">
                                    <label class="mr-2" v-if="settings.autoApprove == false">{{ $t("whitelistSettings.automatic.manual") }}</label>
                                    <label class="mr-2" v-else>{{ $t("whitelistSettings.automatic.auto") }}</label>
                                    <label class="switch">
                                       <input type="checkbox" v-model="settings.autoApprove" @click="settings.autoApprove = !settings.autoApprove">
                                       <span class="slider"></span>
                                    </label>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="card mb-2">
                           <div class="card-body">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-1"><i class="far fa-code color-theme font-13 mr-1"></i> {{ $t("whitelistSettings.sql.title") }}</h6>
                                    <p class="text-center mb-0">{{ $t("whitelistSettings.sql.desc") }}</p>
                                    <hr>
                                 </div>
                                 <div class="col-12 px-1">
                                    <textarea class="form-control" maxlength="200" v-model="settings.sqlScript"></textarea>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div class="col-xl-6 px-1 mb-2">
                        <div class="card mb-0">
                           <div class="card-body">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-1"><i class="far fa-database color-theme font-13 mr-1"></i> {{ $t("whitelistSettings.dataBase.title") }}</h6>
                                    <p class="text-center mb-0">{{ $t("whitelistSettings.dataBase.desc") }}.</p>
                                    <hr>
                                 </div>
                                 <div class="col-12 px-1 mb-12">
                                    <label class="mb-1"><i class="far fa-link color-theme font-13 mr-1"></i> {{ $t("whitelistSettings.dataBase.host") }}</label>
                                    <input type="text" class="form-control" maxlength="200" v-model="settings.hostname" :readonly="modalConfig.connectionOk || !serverS.isAdmin" />
                                 </div>
                                 <div class="col-12 px-1 mb-12">
                                    <label class="mb-1"><i class="far fa-database color-theme font-13 mr-1"></i> {{ $t("whitelistSettings.dataBase.name") }}</label>
                                    <input type="text" class="form-control" maxlength="200" v-model="settings.dbname" :readonly="modalConfig.connectionOk || !serverS.isAdmin" />
                                 </div>
                                 <div class="col-12 px-1 mb-12">
                                    <label class="mb-1"><i class="far fa-project-diagram color-theme font-13 mr-1"></i> {{ $t("whitelistSettings.dataBase.port") }}</label>
                                    <input type="text" class="form-control" maxlength="200" v-model="settings.port" :readonly="modalConfig.connectionOk || !serverS.isAdmin" />
                                 </div>
                                 <div class="col-12 px-1 mb-12">
                                    <label class="mb-1"><i class="far fa-user color-theme font-13 mr-1"></i> {{ $t("whitelistSettings.dataBase.username") }}</label>
                                    <input type="text" class="form-control" maxlength="200" v-model="settings.username" :readonly="modalConfig.connectionOk || !serverS.isAdmin" />
                                 </div>
                                 <div class="col-12 px-1 mb-12">
                                    <label class="mb-1"><i class="far fa-key color-theme font-13 mr-1"></i> {{ $t("whitelistSettings.dataBase.password") }}</label>
                                    <input type="text" class="form-control" maxlength="200" v-model="settings.password" :readonly="modalConfig.connectionOk || !serverS.isAdmin" />
                                    <small class="d-block text-right mt-1" v-if="!serverS.isAdmin"><i class="far fa-exclamation-triangle font-10 mr-1"></i> {{ $t("serverSettings.necessaryOwner") }}</small>
                                 </div>
                                 <div class="col-6 px-1 align-self-center">
                                    <span v-if="modalConfig.connectionOk == true"><i class="far fa-check color-theme font-13 mr-1"></i> {{ $t("whitelistSettings.dataBase.success") }}</span>
                                 </div>
                                 <div class="col-6 px-1 text-right">
                                    <button type="button" class="btn btn-primary" @click="testConnection" :disabled="modalConfig.connectionOk == true">{{ $t("whitelistSettings.button") }}</button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div class="col-12 px-1 text-right">
                        <button type="button" class="btn btn-primary" @click="saveSettings" v-if="modalConfig.connectionOk == true || !serverS.isAdmin">{{ $t("serverSettings.editMessage.buttons.save") }}</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="row" v-else>
      <div class="col-12">
         <div class="card mb-2">
            <div class="card-body d-flex p-10">
               <div class="col">
                  <ul class="nav nav-tabs2">
                     <li class="nav-item">
                        <a class="nav-link show active" data-toggle="tab" href="#one">
                           <i class="far fa-cog font-12 mr-lg-2 d-inline d-md-none d-lg-inline"></i><span class="d-none d-md-inline">{{ $t("whitelistSettings.settings") }}</span>
                        </a>
                     </li>
                     <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#two">
                           <i class="far fa-comments-alt font-12 mr-lg-2 d-inline d-md-none d-lg-inline"></i><span class="d-none d-md-inline">{{ $t("whitelistSettings.messages") }}</span>
                        </a>
                     </li>
                  </ul>
               </div>
               <div class="w-max-content" v-if="modalConfig.connectionOk == true || !serverS.isAdmin">
                  <button class="btn btn-primary h-auto rounded-sm" @click="saveSettings">
                     <i class="fal fa-save font-13 mr-lg-2 d-inline d-sm-none d-lg-inline"></i><span class="d-none d-sm-inline">{{ $t("whitelistSettings.save") }}</span>
                  </button>
               </div>
            </div>
         </div>

         <div class="tab-content mt-0">
            <div class="tab-pane show active" id="one">
               <div class="row">
                  <div class="col-md-6">
                     <div class="alert alert-danger alert-dismissible mb-2" role="alert" v-if="modalConfig.connectionOk != true">
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>
                        <i class="fas fa-times-circle mr-1"></i> {{ $t("whitelistSettings.testConnection") }}
                     </div>
                     <div class="card mb-2">
                        <div class="card-body">
                           <div class="row m-0">
                              <div class="col-12 px-1 mb-1">
                                 <h6 class="text-center mb-1"><i class="far fa-exclamation-triangle color-theme font-13 mr-1"></i> {{ $t("whitelistSettings.correctAnswers") }}</h6>
                                 <p class="text-center mb-0 d-none d-xl-block">{{ $t("whitelistSettings.correctDesc") }}</p>
                                 <hr class="d-none d-xl-block">
                              </div>
                              <div class="col-12 px-1">
                                 <input type="text" class="form-control" v-mask="'#####'" v-model="settings.minimumCorrect" />
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="card mb-2">
                        <div class="card-body">
                           <div class="row m-0">
                              <div class="col-12 px-1 mb-1">
                                 <h6 class="text-center mb-1"><i class="far fa-lock color-theme font-13 mr-1"></i> {{ $t("whitelistSettings.permissionWl") }}</h6>
                                 <p class="text-center mb-0 d-none d-xl-block">{{ $t("whitelistSettings.permisionDesc") }}</p>
                                 <hr class="d-none d-xl-block">
                              </div>
                              <div class="col-12 px-1">
                                 <v-select title="Desktop" :options="permissionsList" v-model="settings.permission" label="name" />
                                 <select title="Mobile" class="form-control" v-model="settings.permission">
                                    <option v-for="(option, index) in permissionsList" :key="index" :value="option">{{ option.name }}</option>
                                 </select>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="card mb-2">
                        <div class="card-body">
                           <div class="row m-0 justify-content-center">
                              <div class="col-12 px-1 mb-1">
                                 <h6 class="text-center mb-1"><i class="far fa-lock color-theme font-13 mr-1"></i> {{ $t("whitelistSettings.validation") }}</h6>
                                 <p class="text-center mb-0">{{ $t("whitelistSettings.validationDesc") }}</p>
                                 <hr>                              
                              </div>
                              <div  class="d-flex">
                                 <label class="mr-2" v-if="settings.autoApprove == false">{{ $t("whitelistSettings.automatic.manual") }}</label>
                                 <label class="mr-2" v-else>{{ $t("whitelistSettings.automatic.auto") }}</label>
                                 <label class="switch">
                                    <input type="checkbox" v-model="settings.autoApprove" @click="settings.autoApprove = !settings.autoApprove">
                                    <span class="slider"></span>
                                 </label>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="card mb-2">
                        <div class="card-body">
                           <div class="row m-0">
                              <div class="col-12 px-1 mb-1">
                                 <h6 class="text-center mb-1"><i class="far fa-code color-theme font-13 mr-1"></i> {{ $t("whitelistSettings.sql.title") }}</h6>
                                 <p class="text-center mb-0 d-none d-xl-block">{{ $t("whitelistSettings.sql.desc") }}</p>
                                 <hr class="d-none d-xl-block">
                              </div>
                              <div class="col-12 px-1">
                                 <textarea class="form-control" maxlength="200" v-model="settings.sqlScript"></textarea>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="card mb-2">
                        <div class="card-body">
                           <div class="row m-0">
                              <div class="col-12 px-1 mb-1">
                                 <h6 class="text-center mb-1"><i class="fas fa-user-tag color-theme font-13 mr-1"></i> {{ $t("whitelistSettings.roleWl") }}</h6>
                                 <p class="text-center mb-0 d-none d-xl-block">{{ $t("whitelistSettings.roleDesc") }}</p>
                                 <hr class="d-none d-xl-block">
                              </div>
                              <div class="col-12 px-1">
                                 <v-select title="Desktop" :options="permissionsList" v-model="settings.role" label="name" />
                                 <select title="Mobile" class="form-control" v-model="settings.role">
                                    <option v-for="(option, index) in permissionsList" :key="index" :value="option">{{ option.name }}</option>
                                 </select>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="card mb-2">
                        <div class="card-body">
                           <div class="row m-0">
                              <div class="col-12 px-1 mb-1">
                                 <h6 class="text-center mb-1"><i class="far fa-text-size color-theme font-13 mr-1"></i> {{ $t("whitelistSettings.initWl") }}</h6>
                                 <p class="text-center mb-0 d-none d-xl-block">{{ $t("whitelistSettings.initWlDesc") }}</p>
                                 <hr class="d-none d-xl-block">
                              </div>
                              <div class="col-12 px-1">
                                 <textarea class="form-control" maxlength="200" v-model="settings.sqlLabel"></textarea>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="col-md-6">
                     <div class="card mb-0">
                        <div class="card-body">
                           <div class="row m-0">
                              <div class="col-12 px-1 mb-1">
                                 <h6 class="text-center mb-1"><i class="far fa-database color-theme font-13 mr-1"></i> {{ $t("whitelistSettings.dataBase.title") }}</h6>
                                 <p class="text-center mb-0 d-none d-xl-block">{{ $t("whitelistSettings.dataBase.desc") }}</p>
                                 <hr class="d-none d-xl-block">
                              </div>
                              <div class="col-12 px-1 mb-12">
                                 <label class="mb-1"><i class="far fa-link color-theme font-13 mr-1"></i> {{ $t("whitelistSettings.dataBase.host") }}</label>
                                 <input type="text" class="form-control" maxlength="200" v-model="settings.hostname" :readonly="modalConfig.connectionOk || !serverS.isAdmin" />
                              </div>
                              <div class="col-12 px-1 mb-12">
                                 <label class="mb-1"><i class="far fa-database color-theme font-13 mr-1"></i> {{ $t("whitelistSettings.dataBase.name") }}</label>
                                 <input type="text" class="form-control" maxlength="200" v-model="settings.dbname" :readonly="modalConfig.connectionOk || !serverS.isAdmin" />
                              </div>
                              <div class="col-12 px-1 mb-12">
                                 <label class="mb-1"><i class="far fa-project-diagram color-theme font-13 mr-1"></i> {{ $t("whitelistSettings.dataBase.port") }}</label>
                                 <input type="text" class="form-control" maxlength="200" v-model="settings.port" :readonly="modalConfig.connectionOk || !serverS.isAdmin" />
                              </div>
                              <div class="col-12 px-1 mb-12">
                                 <label class="mb-1"><i class="far fa-user color-theme font-13 mr-1"></i> {{ $t("whitelistSettings.dataBase.username") }}</label>
                                 <input type="text" class="form-control" maxlength="200" v-model="settings.username" :readonly="modalConfig.connectionOk || !serverS.isAdmin" />
                              </div>
                              <div class="col-12 px-1 mb-12">
                                 <label class="mb-1"><i class="far fa-key color-theme font-13 mr-1"></i> {{ $t("whitelistSettings.dataBase.password") }}</label>
                                 <input type="text" class="form-control" maxlength="200" v-model="settings.password" :readonly="modalConfig.connectionOk || !serverS.isAdmin" />
                                 <small class="d-block text-right mt-1" v-if="!serverS.isAdmin"><i class="far fa-exclamation-triangle font-10 mr-1"></i> {{ $t("serverSettings.necessaryOwner") }}</small>
                              </div>
                              <div class="col-6 px-1 align-self-center">
                                 <span v-if="modalConfig.connectionOk == true"><i class="far fa-check color-theme font-13 mr-1"></i> {{ $t("whitelistSettings.dataBase.success") }}</span>
                              </div>
                              <div class="col-6 px-1 text-right">
                                 <button type="button" class="btn btn-primary" @click="testConnection" :disabled="modalConfig.connectionOk == true">{{ $t("whitelistSettings.button") }}</button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="tab-pane" id="two">
               <div class="row">
                  <div class="col-12">
                     <div class="card mb-2">
                        <div class="card-body p-3">
                           <h6 class="limitador my-0 cursor-pointer" title="Edit" @click="editChannel">
                              <span>{{ $t("ticketSettings.selected") }}: <span class="ml-1" v-if="settings.nameChannel != null">#{{ settings.nameChannel }}</span>
                              <span class="ml-1 text-danger" v-else>#none</span><i class="far fa-pen font-12 ml-3"></i></span>
                           </h6>
                        </div>
                     </div>
                  </div>

                  <div class="col-md-6 col-xl-4" v-if="preview.channel != null">
                     <div class="card mb-2">
                        <div class="card-body p-3">
                           <div class="row m-0 align-items-center">
                              <div class="col">
                                 <h6 class="limitador my-0">{{ $t("ticketSettings.channelMessage") }}</h6>
                              </div>
                              <div class="w-max-content">
                                 <label class="switch">
                                    <input type="checkbox" v-model="preview.channel.enabled" @click="toggleMessage('channelWl')"><span class="slider"></span>
                                 </label>
                              </div>
                              <div class="w-max-content pl-1" v-if="preview.channel.enabled">
                                 <button class="btn btn-outline-primary h-auto rounded-sm" @click="testMessage(preview.channel.tipo, settings.idChannel)"><i class="far fa-share font-11 mr-1"></i> {{ $t("serverSettings.buttons.send") }}</button>
                              </div>
                              <div class="w-max-content pl-1">
                                 <button class="btn btn-primary h-auto rounded-sm" @click="editPreview(preview.channel)"><i class="far fa-pen font-12 mr-1"></i> {{ $t("serverSettings.buttons.edit") }}</button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <PreviewMessageDeck class="m-0 p-0" v-show="preview.channel.enabled" :message="preview.channel" :dadosUsuario="dadosUsuario" />
                  </div>

                  <div class="col-md-6 col-xl-4" v-if="preview.start != null">
                     <div class="card mb-2">
                        <div class="card-body p-3">
                           <div class="row m-0 align-items-center">
                              <div class="col">
                                 <h6 class="limitador my-0">{{ $t("ticketSettings.start") }}</h6>
                              </div>
                              <div class="w-max-content">
                                 <label class="switch">
                                    <input type="checkbox" v-model="preview.start.enabled" @click="toggleMessage('startWl')"><span class="slider"></span>
                                 </label>
                              </div>
                              <div class="w-max-content pl-1" v-if="preview.start.enabled">
                                 <button class="btn btn-outline-primary h-auto rounded-sm" @click="testMessage(preview.start.tipo, settings.idChannel)"><i class="far fa-share font-11 mr-1"></i> {{ $t("serverSettings.buttons.test") }}</button>
                              </div>
                              <div class="w-max-content pl-1">
                                 <button class="btn btn-primary h-auto rounded-sm" @click="editPreview(preview.start)"><i class="far fa-pen font-12 mr-1"></i> {{ $t("serverSettings.buttons.edit") }}</button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <PreviewMessageDeck class="m-0 p-0" v-show="preview.start.enabled" :message="preview.start" :dadosUsuario="dadosUsuario" />
                  </div>

                  <div class="col-md-6 col-xl-4" v-if="preview.end != null">
                     <div class="card mb-2">
                        <div class="card-body p-3">
                           <div class="row m-0 align-items-center">
                              <div class="col">
                                 <h6 class="limitador my-0">{{ $t("ticketSettings.end") }}</h6>
                              </div>
                              <div class="w-max-content">
                                 <label class="switch">
                                    <input type="checkbox" v-model="preview.end.enabled" @click="toggleMessage('endWl')"><span class="slider"></span>
                                 </label>
                              </div>
                              <div class="w-max-content pl-1" v-if="preview.end.enabled">
                                 <button class="btn btn-outline-primary h-auto rounded-sm" @click="testMessage(preview.end.tipo, settings.idChannel)"><i class="far fa-share font-11 mr-1"></i> {{ $t("serverSettings.buttons.test") }}</button>
                              </div>
                              <div class="w-max-content pl-1">
                                 <button class="btn btn-primary h-auto rounded-sm" @click="editPreview(preview.end)"><i class="far fa-pen font-12 mr-1"></i> {{ $t("serverSettings.buttons.edit") }}</button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <PreviewMessageDeck class="m-0 p-0" v-show="preview.end.enabled" :message="preview.end" :dadosUsuario="dadosUsuario" />
                  </div>
               </div>
            </div>
         </div>
      </div>

      <!-- modalChannel -->
      <div class="modal fade" id="modalChannel" tabindex="-1" aria-labelledby="modalChannelLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title h4" id="modalChannelLabel">{{ $t("serverSettings.selectChannel.title") }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="row m-0">
                     <div class="col-12 px-1 mb-2">
                        <div class="card mb-0">
                           <div class="card-body">
                              <div class="col-12 px-1 mb-1">
                                 <h6 class="text-center mb-0">{{ $t("serverSettings.selectChannel.sub") }}</h6>
                                 <hr>
                              </div>
                              <div class="col-12 px-1 mb-1">
                                 <div class="cards">
                                    <channel v-for="(channel, index) in channelsList" :key="index" :channel="channel" :selected="settings.idChannel" @selectChannel="saveChannel($event)" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-secondary" data-dismiss="modal">{{ $t("serverSettings.selectChannel.buttons.close") }}</button>
               </div>
            </div>
         </div>
      </div>

      <!-- modalPreview -->
      <div class="modal fade" id="modalPreview" tabindex="-1" aria-labelledby="modalPreviewLabel" aria-hidden="true">
         <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title h4" id="modalPreviewLabel">{{ $t("serverSettings.editMessage.title") }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="row m-0" v-if="message != null">
                     <div class="class_preview">
                        <PreviewMessageDeck :message="message" :dadosUsuario="dadosUsuario" class="class_preview_div" />
                     </div>
                     <div class="class_message">
                        <MessageDeck :message="message" @attObjetoMessage="attObjetoMessage($event)" />
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-primary" @click="savePreview">{{ $t("serverSettings.editMessage.buttons.save") }}</button>
                  <button type="button" class="btn btn-round btn-secondary" data-dismiss="modal">{{ $t("serverSettings.editMessage.buttons.close") }}</button>
               </div>
            </div>
         </div>
      </div>

      <button class="btn btn-primary btn-float" @click="saveSettings" v-if="(modalConfig.connectionOk == true || !serverS.isAdmin) && showSave"><i class="fal fa-save"></i></button>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import MessageDeck from '@/components/MessageDeck.vue'
import channel from '@/components/whitelist/Channel.vue'
import PreviewMessageDeck from '@/components/PreviewMessageDeck.vue'

export default {
   name: 'Whitelist_settings',
   data: function () {
		return {
         message: null,
         showSave: false,
         settings: {
            'id': null, 'hostname': null, 'port': null, 'dbname': null, 'username': null, 'password': null, 'idServer': null, 'idChannel': null, 'nameChannel': null, 'minimumCorrect': null, 
            'idPermission': null, 'namePermission': null, 'idRole': null, 'sqlLabel': null, 'sqlScript': null, 'autoApprove': false
         },
         preview: {'channel': null, 'start': null, 'end': null},
         modalConfig: {'connectionOk': false},
      }
   },
   computed: {
		...mapState({
         permissionsList: state => state.permissionsList,
         dadosUsuario: state => state.dadosUsuario,
         channelsList: state => state.channelsList,
			urlRest: state => state.urlRest,
			serverS: state => state.serverS
		}),
	},
   watch: {
      settings: {
         handler: function (newValue, oldValue) {
            if (newValue && newValue.idServer && oldValue && oldValue.idServer) {
               this.showSave = true
            }
         },
         deep: true
      },
   },
   components: {
      channel, PreviewMessageDeck, MessageDeck
   },
   methods: {
      getSettings : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicos/getsettingswl',
            params: {
               idServer: this.serverS.id_s
            }
         }).then(response => {
            this.preview = response.data.preview;
            this.settings = {
               'id': response.data.settings.id,
               'hostname': response.data.settings.hostname,
               'port': response.data.settings.port,
               'dbname': response.data.settings.dbname,
               'username': response.data.settings.username,
               'password': response.data.settings.password,
               'idServer': response.data.settings.idServer,
               'idChannel': response.data.settings.idChannel,
               'nameChannel': response.data.settings.nameChannel,
               'minimumCorrect': response.data.settings.minimumCorrect,
               'idPermission': response.data.settings.idPermission,
               'namePermission': response.data.settings.namePermission,
               'idRole': response.data.settings.idRole,
               'sqlLabel': response.data.settings.sqlLabel,
               'sqlScript': response.data.settings.sqlScript,
               'autoApprove': response.data.settings.autoApprove,
               'permission': this.permissionsList.find(perm => perm.id == response.data.settings.idPermission),
               'role': this.permissionsList.find(perm => perm.id == response.data.settings.idRole)
            }
            // console.log(response.data.settings)
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      editChannel : function () {
         $('#modalChannel').modal('show')
      },
      saveChannel : function (channel) {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.settings.idChannel = channel.id;
         this.settings.nameChannel = channel.name;

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicos/alterchannelwl',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.settings

         }).then(() => {
            $('#modalChannel').modal('hide')

            this.$toast.fire({
               icon: 'success',
               title: this.$t('serverSettings.swal.saved')
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      saveSettings : function () {
         if (this.settings.dbname == null || String(this.settings.dbname).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Invalid database name!'
            })

            return
         } else if (this.settings.hostname == null || String(this.settings.hostname).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Invalid hostname!'
            })

            return
         } else if (this.settings.port == null || String(this.settings.port).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Invalid port!'
            })

            return
         } else if (this.settings.username == null || String(this.settings.username).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Invalid username!'
            })

            return
         } else if (this.settings.permission == null || this.settings.permission.id == null) {
            this.$toast.fire({
               icon: 'error',
               title: 'Invalid permission!'
            })

            return
         } else if (this.settings.password == null) {
            this.settings.password = "";
         }

         this.$store.dispatch('alternarTelaCarregamento', true)
         this.settings.idRole = this.settings.role != null ? this.settings.role.id : null
         this.settings.idPermission = this.settings.permission.id
         this.settings.namePermission = this.settings.permission.name

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicos/savesettingswl',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.settings

         }).then(() => {
            let attServer = this.serverS;
            attServer.idPermissionWl = this.settings.idPermission;
            this.$store.dispatch('updateServerS', attServer)

            this.showSave = false

            this.$toast.fire({
               icon: 'success',
               title: this.$t('whitelistSettings.savedSettings')
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            if (this.settings.idPermission != null) {
               this.settings.permission = this.permissionsList.filter(perm => perm.id == this.settings.idPermission)[0]
            }

            if (this.settings.idRole != null) {
               this.settings.role = this.permissionsList.filter(perm => perm.id == this.settings.idRole)[0]
            }

            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      testConnection : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicos/testconnection',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.settings

         }).then(() => {
            this.modalConfig.connectionOk = true;

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else if (error.response.status == 400) {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Failed to connect!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      editPreview : function (preview) {
         this.message = null;
         
         setTimeout(() => {
            this.message = JSON.parse(JSON.stringify(preview));
            $('#modalPreview').modal('show')
         }, 50);
      },
      attObjetoMessage: function(e) {
			this.message = e;
		},
      savePreview : function () {
         this.$store.dispatch('saveMessage', this.message).then(() => {
            $('#modalPreview').modal('hide')

				this.getSettings()
            this.message = null;

            this.$toast.fire({
               icon: 'success',
               title: this.$t('serverSettings.swal.message')
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         })
      },
      toggleMessage : function (type) {
         let value = null;

         setTimeout(() => {
            switch (type) {
               case "channelWl":
                  value = this.preview.channel.enabled
               break;
               case "startWl":
                  value = this.preview.start.enabled
               break;
               case "endWl":
                  value = this.preview.end.enabled
               break;
            }

            this.$store.dispatch('toggleMessage', {type: type, value: value}).then(() => {
               this.$toast.fire({
                  icon: 'success',
                  title: this.$t("serverSettings.swal.message")
               })
            }).catch((error) => {
               if (error.response != undefined) {
                  if (error.response.status == 408) {
                     this.$store.dispatch('deslogar')

                     this.$toast.fire({
                        icon: 'warning',
                        title: this.$t("serverSettings.swal.expired")
                     });
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: 'Error: ' + error.response.status
                     });
                  }
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: error
                  });
               }
            })
         }, 100);
      },
      testMessage : function (type, idChannel) {
         this.$store.dispatch('sendMessage', {'type': type, 'idServer': this.serverS.id_s, 'idChannel': idChannel}).then(() => {
            this.$toast.fire({
               icon: 'success',
               title: this.$t("serverSettings.messageSent")
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         })
      }
   },
   mounted() {
      // let ref = this;
      // console.log(ref.serverS)
      // console.log(ref.dadosUsuario)
      this.$store.dispatch('getPermissions')
      this.$store.dispatch('getChannels')
      this.getSettings()
   }
}

</script>