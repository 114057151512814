import Vue from 'vue'
import $ from 'jquery'
import Vuex from 'vuex'
import errorOnLoadImg from '@/assets/images/erro_img.png'

Vue.use(Vuex)

const store = {
   state: {
      // Tiago //////////////////////////////////////////////////////////////////
      // clientId : "1196797408781279342",
		// redirectUri : encodeURIComponent("http://10.1.1.218:8080/"),
      // urlEcommerce: 'http://10.1.1.218:8090/',
      // urlRest: 'http://10.1.1.237:8080/DeckService-0.0.1/',
      // urlSocketTicket: 'ws://10.1.1.237:8080/DeckService-0.0.1/socket/ticket',
      ///////////////////////////////////////////////////////////////////////////

      // Ozaldinho //////////////////////////////////////////////////////////////////
      // clientId : "1285585580977033226",
		// redirectUri : encodeURIComponent("http://10.1.1.40:8081/"),
      // urlEcommerce: 'http://10.1.1.40:8090/',
      // urlRest: 'http://10.1.1.40:8080/DeckService-0.0.1/',
      // urlSocketTicket: 'ws://10.1.1.40:8080/DeckService-0.0.1/socket/ticket',
      ///////////////////////////////////////////////////////////////////////////

      // Producao //////////////////////////////////////////////////////////////////
      clientId : "1102668367032238221",
      redirectUri : encodeURIComponent("https://bot.appdeck.online/"),
      urlEcommerce: 'https://ecommerce.appdeck.online/',
      urlSocketTicket: 'wss://appdeck.online:8446/DeckService/socket/ticket',
      urlRest: 'https://appdeck.online:8446/DeckService/',
      ///////////////////////////////////////////////////////////////////////////
      
      isCarregando: false,
      isLogado: false,
      channelsList: [],
      permissionsList: [],
      categoriesList: [],
      ecommerceCategoriesList: [],
      serverS: {
         id: null,
         id_s: null,
         name: null,
         id_dono: null,
         isAdmin: false,
         channelWelcome: null,
         channelWelcomeName: null,
         channelExit: null,
         channelExitName: null,
         url_icon: null,
         roles: [],
         idPermissionWl: null,
         idPermissionTicket: null,
         idPermissionEcommerce: null
      },
      dadosUsuario: {},
      servidores: [],
      tokenSessao: null,
      tokenApi: null,
      tokenType: null
   },
   mutations: {
      /* eslint-disable-next-line no-unused-vars */
      imageError : (state, e) => {
         e.target.src = errorOnLoadImg
      },
      verificarCredenciais : (state) => {
         let myDate = new Date(Date.now()).toLocaleString().split(',')[0];
         var fragment = new URLSearchParams(window.location.hash.slice(1));
         var [accessToken, tokenType] = [fragment.get('access_token'), fragment.get('token_type')];
         var obterDiscord = false;
         state.isCarregando = true;

         if (localStorage.accessToken == undefined || localStorage.tokenType == undefined || localStorage.ultimoLogin == undefined) {
            if (accessToken != null && tokenType != null && myDate != null) {
               localStorage.accessToken = accessToken;
               localStorage.tokenType = tokenType;
               localStorage.ultimoLogin = myDate;
               obterDiscord = true;
               
            } else {
               state.isLogado = false ;
            }
         } else {
            accessToken = localStorage.accessToken;
            tokenType = localStorage.tokenType;

            if (localStorage.ultimoLogin == myDate) {
               obterDiscord = true;
            } else {
               store.mutations.deslogar(state)
            }
         }

         if (obterDiscord) {
            fetch('https://discord.com/api/users/@me', {
               headers: {
                  authorization: `${tokenType} ${accessToken}`
               }
            })
            .then(result => result.json())
            .then(response => {
               state.dadosUsuario = response
               state.dadosUsuario.usuarioFoto = `https://cdn.discordapp.com/avatars/${response.id}/${response.avatar}.jpg`
               state.dadosUsuario.usuarioBanner = `https://cdn.discordapp.com/banners/${response.id}/${response.banner}.jpg`
               localStorage.accessToken = accessToken
               localStorage.tokenType = tokenType
               localStorage.ultimoLogin = myDate

               Vue.prototype.$axios({
                  method: 'get',
                  url: state.urlRest +'login',
                  params: {
                     idDiscord: response.id,
                     username: response.username,
                     email: response.email,
                     tokenType: tokenType,
                     accessToken: accessToken
                  }
               }).then(function (response) {
                  state.dadosUsuario.userAdmin = response.data.userAdmin;
                  state.servidores = response.data;
                  state.tokenSessao = response.data.tokenSessao;
                  state.tokenApi = response.data.tokenApi;
                  state.isLogado = true;

               }).finally(() => {
                  state.isCarregando = false;
               });
            }).catch(function () {
               store.mutations.deslogar(state)
            });
         } else {
            state.isCarregando = false;
         }
      },
      deslogar : function () {
         localStorage.removeItem('accessToken')
         localStorage.removeItem('tokenType')
         localStorage.removeItem('ultimoLogin')

         window.location.href = window.location.origin;
      },
      alternarTelaCarregamento : (state, isCarregando) => {
         state.isCarregando = isCarregando;
      },
      updateServerS : (state, serverS) => {
         state.serverS = serverS;
         setTimeout(() => $(".page-loader-wrapper").fadeOut(), 250);
      },
      getChannels : state => {
         Vue.prototype.$axios({
            method: 'get',
            url: state.urlRest +'servicos/channelsbyid',
            params: {
               idServer: state.serverS.id_s
            }
         }).then(response => {
				state.channelsList = response.data;

         }).catch(function (error) {
            if (error.response != undefined && error.response.status == 408) {
               store.mutations.deslogar(state)
            }
         });
      },
      getPermissions : state => {
         Vue.prototype.$axios({
            method: 'get',
            url: state.urlRest +'servicos/getpermissions',
            params: {
               idServer: state.serverS.id_s
            }
         }).then(response => {
				state.permissionsList = response.data;

         }).catch(function (error) {
            if (error.response != undefined && error.response.status == 408) {
               store.mutations.deslogar(state)
            }
         });
      },
      getCategories : state => {
         Vue.prototype.$axios({
            method: 'get',
            url: state.urlRest +'servicos/getcategories',
            params: {
               idServer: state.serverS.id_s
            }
         }).then(response => {
				state.categoriesList = response.data;

         }).catch(function (error) {
            if (error.response != undefined && error.response.status == 408) {
               store.mutations.deslogar(state)
            }
         });
      },
      getEcommerceCategories : state => {
         Vue.prototype.$axios({
            method: 'get',
            url: state.urlRest +'servicos/getEcommerceCategories',
            params: {
               idServer: state.serverS.id_s
            }
         }).then(response => {
				state.ecommerceCategoriesList = response.data;

         }).catch(function (error) {
            if (error.response != undefined && error.response.status == 408) {
               store.mutations.deslogar(state)
            }
         });
      }
   },
   actions: {
      imageError : ({commit}, e) => {
         commit('imageError', e)
      },
      verificarCredenciais : ({commit}) => {
         commit('verificarCredenciais')
      },
      getPermissoesUsuario : ({commit}, condominio) => {
         commit('getPermissoesUsuario', condominio)
      },
      alternarTelaCarregamento : ({commit}, isCarregando) => {
         commit('alternarTelaCarregamento', isCarregando)
      },
      updateServerS : ({commit}, serverS) => {
         commit('updateServerS', serverS)
      },
      saveMessage : ({commit}, message) => {
         return new Promise((resolve, reject) => {
            commit('alternarTelaCarregamento', true)

            Vue.prototype.$axios({
               method: 'post',
               url: store.state.urlRest +'servicos/savePreview',
               headers: {
                  'Content-Type': 'application/json'
               },
               data: message

            }).then(() => {
               resolve()

            }).catch((error) => {
               reject(error)
               
            }).finally(() => {
               commit('alternarTelaCarregamento', false)
            });
         });
      },
      toggleMessage : ({commit}, {type, value}) => {
         return new Promise((resolve, reject) => {
            commit('alternarTelaCarregamento', true)

            Vue.prototype.$axios({
               method: 'post',
               url: store.state.urlRest +'servicos/toggleMessage',
               params: {
                  idServer: store.state.serverS.id_s,
                  type: type,
                  value: value
               }
            }).then(() => {
               resolve()

            }).catch((error) => {
               reject(error)
               
            }).finally(() => {
               commit('alternarTelaCarregamento', false)
            });
         });
      },
      sendMessage : ({commit}, message) => {
         return new Promise((resolve, reject) => {
            commit('alternarTelaCarregamento', true)

            Vue.prototype.$axios({
               method: 'post',
               url: store.state.urlRest +'servicos/sendMessage',
               headers: {
                  'Content-Type': 'application/json'
               },
               data: message

            }).then(() => {
               resolve()

            }).catch((error) => {
               reject(error)
               
            }).finally(() => {
               commit('alternarTelaCarregamento', false)
            });
         });
      },
      deslogar : context => context.commit('deslogar'),
      getChannels : context => context.commit('getChannels'),
      getCategories : context => context.commit('getCategories'),
      getPermissions : context => context.commit('getPermissions'),
      getEcommerceCategories : context => context.commit('getEcommerceCategories')
   },
   modules: {
   }
}

export default new Vuex.Store(store);